<template>
  <main :class="hasSelection ? '' : 'layout-hasColG'">
    <header class="layer-primary">
      <div class="main-img">
        <img loading="lazy" alt="Maine-et-Loire.fr" src="@/assets/img_assmat.jpg" />
      </div>
    </header>
    
    <section v-if="error">Un problème est survenu, nous ne pouvons pas faire suite à votre demande.</section>
    
    <section v-if="isUserLoaded" role="main" class="section-content fiche-assmat" id="section-content">
      <header>
        <encartSelection></encartSelection>
        
        <ul id="site-nav-breadcrumbs" class="site-nav-breadcrumbs">
          <li><router-link :to="{name: 'accueil'}">Accueil</router-link></li>
          <li><router-link :to="{name: 'recherche'}">Recherche</router-link></li>
          <li>{{ assmat.prenom }} {{ assmat.nom }}</li>
        </ul>
        
        <h1>{{ assmat.prenom }} {{ assmat.nom }}</h1>
        
        <p v-if="assmat.presentation" class="intro" id="introPage">
          {{ assmat.presentation.presentation }}
        </p>
        
        <div class="toolbox">
          <p v-if="assmat.dateModification" id="dateEasyReading">
            Dernière mise à jour le
            <time :datetime="assmat.dateModification">{{ getLongDate(assmat.dateModification) }}</time>
          </p>
          
          <button type="button" name="button" @click="selectionToggle(assmat)" class="fiche-assmat-btn-favorite">
            <span v-if="isSelected">
              <span class="fa fa-solid fa-star" :class="{ active: isSelected }"></span>
              Retirer cette fiche de
            </span>
            <span v-else>
              <span class="fa fa-regular fa-star"></span>
              Ajouter cette fiche à
            </span>
            ma sélection
          </button>
          
          <ul class="toolbox-tools" id="toolbox"></ul>
        </div>
      </header>
      
      <div class="link-back-wrapper">
        <a href="#" @click.prevent="$router.go(-1)" class="link-back">Retour à la liste des résultats</a>
      </div>
      
      <div>
        <aside class="sidebar-secondary">
          <mapContainer v-if="assmat.adresse.latitude" :markers="getMarkers([assmat])" :height="500"></mapContainer>
        </aside>
        
        <article id="main-content">
          <h2>Coordonnées</h2>
          <dl class="dl-table-like">
            <dt>Adresse</dt>
            <dd>
              {{ assmat.adresse.numero }}
              {{ assmat.adresse.libelleAdresse }}
              {{ assmat.adresse.complementAdresse }}
              {{ assmat.adresse.codePostal }}
              {{ assmat.adresse.commune }}
            </dd>
            <dt v-if="assmat.coordonnees.numeroTelephoneFixe">Téléphone fixe</dt>
            <dd v-if="assmat.coordonnees.numeroTelephoneFixe">
              <phoneLink :phoneNumber="assmat.coordonnees.numeroTelephoneFixe"></phoneLink>
            </dd>
            <dt v-if="assmat.coordonnees.numeroTelephonePortable">Téléphone portable</dt>
            <dd v-if="assmat.coordonnees.numeroTelephonePortable">
              <phoneLink :phoneNumber="assmat.coordonnees.numeroTelephonePortable"></phoneLink>
            </dd>
            <dt v-if="assmat.coordonnees && assmat.coordonnees.precisionsModalitesContact">Modalités de contact</dt>
            <dd v-if="assmat.coordonnees && assmat.coordonnees.precisionsModalitesContact">
              {{ assmat.coordonnees.precisionsModalitesContact }}
            </dd>
            <dt v-if="assmat.coordonnees.courriel">Courriel</dt>
            <dd v-if="assmat.coordonnees.courriel">
              <a class="mail" :href="getMailtoLink()" :title="getMailTitle(assmat.coordonnees.courriel)">{{ assmat.coordonnees.courriel }}</a>
            </dd>
            <dt>Exerce</dt>
            <dd v-if="assmat.modalitesAccueil && assmat.modalitesAccueil.faitPartieMam">Dans une maison d’assistants maternels</dd>
            <dd v-else>À mon domicile</dd>
          </dl>
          
          <h2>Disponibilités</h2>
          <div class="grid-2-small-1 grid-with-gutter fiche-assmat-dispos">
            <div class="colored-box">
              <p class="colored-box-strong-text">Actuellement</p>
              <div v-if="!assmat.dispoActuelle.aucuneDispoActuelle && (assmat.dispoActuelle.dispoPeriscolaire || assmat.dispoActuelle.dispoVacancesScolaires || realDispoActuelle.length)">
                <p v-for="d in realDispoActuelle">
                  <span class="fa fa-solid fa-calendar-check"></span>
                  <span class="colored-box-strong-text"> {{ d.nombrePlaces }}</span> place<span v-if="d.nombrePlaces > 1">s</span> disponible<span v-if="d.nombrePlaces > 1">s</span> pour un enfant de {{ d.libelleTrancheAgeSolis }}
                </p>
                <p>
                  <span v-if="assmat.dispoActuelle && assmat.dispoActuelle.dispoPeriscolaire">
                    <span class="fa fa-solid fa-calendar-check"></span>
                    J'ai des disponibilités
                  </span>
                  <span v-else>
                    <span class="fa fa-solid fa-calendar-times"></span>
                    Je n’ai pas de disponibilité
                  </span>
                  pour du périscolaire
                </p>
                <p>
                  <span v-if="assmat.dispoActuelle && assmat.dispoActuelle.dispoVacancesScolaires">
                    <span class="fa fa-solid fa-calendar-check"></span>
                    J'ai des
                  </span>
                  <span v-else>
                    <span class="fa fa-solid fa-calendar-times"></span>
                    Je n’ai pas de
                  </span>
                  disponibilité pour les vacances scolaires
                </p>
                <p v-if="assmat.dispoActuelle.precisions">
                  Précisions : {{ assmat.dispoActuelle.precisions }}
                </p>
              </div>
              <p v-else-if="assmat.dispoActuelle.aucuneDispoActuelle">
                <span class="fa fa-solid fa-calendar-times"></span>
                Aucune disponibilité actuellement
              </p>
              <p v-else>
                <span class="fa fa-regular fa-question-circle"></span>
                Disponibilité non renseignée
              </p>
            </div>
            <div class="colored-box">
              <p class="colored-box-strong-text">Disponibilité future</p>
              <div v-for="df in assmat.disposFutures">
                <p class="colored-box-large-text">
                  À partir du
                  <time :datetime="df.dateDebut">{{ getLongDate(df.dateDebut) }}</time>
                </p>
                <p>
                  <span class="fa fa-solid fa-calendar-check"></span>
                  Disponible pour un enfant de {{df.libelleTrancheAgeSolis}}
                </p>
              </div>
              
              <p v-if="!assmat.disposFutures">
                <span class="fa fa-solid fa-calendar-times"></span>
                Aucune disponibilité future renseignée
              </p>
              
            </div>
          </div>
          
          <h2>À propos de moi</h2>
          <h3 v-if="assmat.modalitesAccueil && (assmat.dateNaissance || assmat.modalitesAccueil.faitPartieMam || assmat.modalitesAccueil.accepteEnfantHandicape || assmat.modalitesAccueil.accepteFaireRemplacements || assmat.modalitesAccueil.accepteHorairesAtypiques || assmat.modalitesAccueil.accepteTempsPartiel || assmat.modalitesAccueil.travailMercredi || assmat.modalitesAccueil.proposeRepas || assmat.modalitesAccueil.possedePermisConduire || assmat.modalitesAccueil.possedeEspaceExterieur || assmat.modalitesAccueil.possedeAnimaux || assmat.modalitesAccueil.fumeur)">Profil</h3>
          <ul v-if="assmat.modalitesAccueil">
            <li v-if="assmat.dateNaissance">J’ai {{ getAge(assmat.dateNaissance) }} ans</li>
            <li v-if="assmat.modalitesAccueil.faitPartieMam">Je fais parti d’une <abbr title="Maisons d’Assistants Maternels">MAM</abbr></li>
            <li v-if="assmat.modalitesAccueil.accepteEnfantHandicape">Je peux accueillir un enfant en situation de handicap</li>
            <li v-if="assmat.modalitesAccueil.accepteFaireRemplacements">J’accepte de faire des remplacements</li>
            <li v-if="assmat.modalitesAccueil.accepteHorairesAtypiques">J’accepte de faire des horaires atypiques</li>
            <li v-if="assmat.modalitesAccueil.accepteTempsPartiel">J’accepte de faire du temps partiel</li>
            <li v-if="assmat.modalitesAccueil.travailMercredi">Je travaille le mercredi</li>
            <li v-if="assmat.modalitesAccueil.proposeRepas">Je propose des repas</li>
            <li v-if="assmat.modalitesAccueil.possedePermisConduire">Je possède le permis de conduire</li>
            <li v-if="assmat.modalitesAccueil.possedeEspaceExterieur">Je possède un espace extérieur</li>
            <li v-if="assmat.modalitesAccueil.possedeAnimaux">Je possède un/des animaux</li>
            <li v-if="assmat.modalitesAccueil.fumeur">Je suis non-fumeur/non-fumeuse</li>
          </ul>
          
          <h3 v-if="assmat.modalitesAccueil && assmat.modalitesAccueil.precisionsModalitesAccueil">Compléments d’information</h3>
          <p v-if="assmat.modalitesAccueil && assmat.modalitesAccueil.precisionsModalitesAccueil">
            {{ assmat.modalitesAccueil.precisionsModalitesAccueil }}
          </p>
          
          <h3 v-if="assmat.presentation && (assmat.presentation.premiersSecours || assmat.presentation.formationsCompetences.trim().length > 0)">Formation / Compétences</h3>
          <p v-if="assmat.presentation && assmat.presentation.premiersSecours" class="colored-box fiche-assmat-premiers-secours">
            <span class="fa fa-solid fa-medkit"></span> J’ai une initiation / formation aux premiers secours obtenue le <time :datetime="assmat.presentation.dateObtentionPremierSecours">{{ getLongDate(assmat.presentation.dateObtentionPremierSecours) }}</time>.
          </p>
          <p v-if="assmat.presentation && assmat.presentation.formationsCompetences">
            {{ assmat.presentation.formationsCompetences }}
          </p>
          
          <h3 v-if="assmat.modalitesAccueil && assmat.modalitesAccueil.precisionsHorairesConges">Congés et horaires</h3>
          <p v-if="assmat.modalitesAccueil && assmat.modalitesAccueil.precisionsHorairesConges">
            {{ assmat.modalitesAccueil.precisionsHorairesConges }}
          </p>
          
          <h3 v-if="assmat.modalitesAccueil && assmat.modalitesAccueil.tarifs">Tarifs</h3>
          <p v-if="assmat.modalitesAccueil && assmat.modalitesAccueil.tarifs">
            {{ assmat.modalitesAccueil.tarifs }}
          </p>
          
          <div class="essential">
            <h3>Agrément</h3>
            <dl class="dl-table-like">
              <dt>
                Date du 1
                <sup>er</sup>
                agrément :
              </dt>
              <dd>
                <time :datetime="assmat.agrement.datePremierAgrement">{{ getLongDate(assmat.agrement.datePremierAgrement) }}</time>
              </dd>
              <dt>Date du dernier agrément :</dt>
              <dd>
                <time :datetime="assmat.agrement.dateDernierRenouvellement">{{ getLongDate(assmat.agrement.dateDernierRenouvellement) }}</time>
              </dd>
              <dt>Date de fin d'agrément :</dt>
              <dd>
                <time :datetime="assmat.agrement.dateFinAgrement">{{ getLongDate(assmat.agrement.dateFinAgrement) }}</time>
              </dd>
            </dl>
            <p v-if="assmat.agrement.types">Type d’agrément :</p>
            <ul>
              <li v-for="type in assmat.agrement.types">
                {{ type.nombreEnfants }} enfant<span v-if="type.nombreEnfants > 1">s</span> âgé<span v-if="type.nombreEnfants > 1">s</span> de {{ type.trancheAge }}
              </li>
            </ul>
            
            <p>
              <small>
                <span class="fa fa-solid fa-info-circle"></span> Ces informations vous sont transmises par le Département de Maine-et-Loire.
              </small>
            </p>
          </div>
        </article>
      </div>
    </section>
  </main>
</template>

<script>
import mapContainer from '@/components/mapContainer.vue';
import phoneLink from '@/components/phoneLink.vue';
import encartSelection from '@/components/encartSelection.vue';
import { mapActions } from 'vuex';

export default {
  name: 'FicheAssmat',
  components: {
    mapContainer,
    phoneLink,
    encartSelection
  },
  data() {
    return {
      assmat: {
        adresse: {},
        presentation: {},
        coordonnees: {},
        modalitesAccueil: {},
        dispoActuelle: {},
        agrement: {
          types: {}
        }
      },
      isUserLoaded: false,
      error: false,
      isSelected: false
    };
  },
  props: {
    userId: {
      required: true
    }
  },
  computed: {
    hasSelection: function() {
      return this.$store.getters.assmats.length
    },
    // Nécessaire pour supprimer les dispos à 0 renvoyées par l'API
    realDispoActuelle: function() {
      return this.assmat.dispoActuelle.disposTranchesAgeSolis.filter(x => x.nombrePlaces > 0)
    }
  },
  mounted() {
    this.$http
    .get('assmat/' + this.userId)
    .then((resp) => {
      this.assmat = resp.data;
      document.title = `Fiche de ${this.assmat.prenom} ${this.assmat.nom} - Assistant maternel (49)`
    })
    .then((_) => {
      this.isUserLoaded = true;
      let isAssmatExists = this.$store.getters.isAssmatExists(this.assmat.id);
      if (isAssmatExists) this.isSelected = true;
    })
    .catch((_) => {
      this.error = true;
    });
  },
  methods: {
    ...mapActions(['addAssmat', 'removeAssmat']),
    getMailtoLink: function () {
      return 'mailto:' + this.assmat.coordonnees.courriel;
    },
    getMailTitle: function(mail) {
      return `Contacter cet assistant maternel à l’adresse ${mail} - Fenêtre de messagerie`
    },
    getAge: function (day) {
      let birthday = new Date(day);
      let ageDifMs = Date.now() - birthday.getTime();
      let ageDate = new Date(ageDifMs); // miliseconds from epoch
      return Math.abs(ageDate.getUTCFullYear() - 1970);
    },
    selectionToggle(assmat) {
      if (this.isSelected) {
        this.removeAssmat(assmat);
        this.isSelected = false;
      } else {
        this.addAssmat(assmat);
        this.isSelected = true;
      }
    }
  }
};
</script>
